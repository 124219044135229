import dayjs from 'dayjs';
import {
    parseTimezone,
    formatDateTime,
    isSameDay,
    FULL_DATE_TIME_FORMAT,
    FULL_DATE_FORMAT,
    TIME_ONLY_FORMAT,
    DAY_FORMAT,
    formatMinutes,
} from '../../utils';

export interface AttrsBase {
    rideUid?: string;
    startStationCode?: string;
    startStationName: string;
    startStationId: string;
    destinationStationCode?: string;
    destinationStationName: string;
    destinationStationId: string;
    arrivalTime: string;
    departureTime: string;
    transportType?: 'BUS' | 'TRAIN';
}

export default class RideBase extends Object {
    startStationCode!: string;
    startStationName!: string;
    startStationId!: string;
    destinationStationCode!: string;
    destinationStationName!: string;
    destinationStationId!: string;
    arrivalTime!: string;
    departureTime!: string;

    transportType!: 'BUS' | 'TRAIN';

    arrivalTimeText: string;
    departureTimeText: string;
    arrivalTimeTitle: string;
    departureTimeTitle: string;
    departureTimeDayTitle: string;

    FULL_DATE_TIME_FORMAT: string = FULL_DATE_TIME_FORMAT;
    FULL_DATE_FORMAT: string = FULL_DATE_FORMAT;
    TIME_ONLY_FORMAT: string = TIME_ONLY_FORMAT;
    DAY_FORMAT: string = DAY_FORMAT;

    constructor(object: AttrsBase) {
        super(object);
        Object.assign(this, object);

        this.arrivalTimeText = this.arrivalTime
            ? formatDateTime(this.arrivalTime, this.TIME_ONLY_FORMAT, true)
            : '--:--';
        this.departureTimeText = this.departureTime
            ? formatDateTime(this.departureTime, this.TIME_ONLY_FORMAT, true)
            : '--:--';
        this.arrivalTimeTitle = this.arrivalTime
            ? formatDateTime(this.arrivalTime, this.FULL_DATE_TIME_FORMAT, true)
            : '--, -- --- ----';
        this.departureTimeTitle = this.departureTime
            ? formatDateTime(
                  this.departureTime,
                  this.FULL_DATE_TIME_FORMAT,
                  true
              )
            : '--, -- --- ----';
        this.departureTimeDayTitle = this.departureTime
            ? formatDateTime(this.departureTime, this.FULL_DATE_FORMAT, true)
            : '--, -- --- ----';
    }

    get departureUtcOffset(): number {
        const { departureTime } = this;
        if (departureTime === undefined || departureTime === null) {
            return 0;
        }
        return parseTimezone(departureTime);
    }

    get arrivalUtcOffset(): number {
        const { arrivalTime } = this;
        if (arrivalTime === undefined || arrivalTime === null) {
            return 0;
        }
        return parseTimezone(arrivalTime);
    }

    get departureDay(): string {
        const { departureTime } = this;
        if (!departureTime) {
            return '--';
        }
        return formatDateTime(departureTime, this.DAY_FORMAT, true);
    }
    get arrivalDay(): string {
        const { arrivalTime } = this;
        if (!arrivalTime) {
            return '--';
        }
        return formatDateTime(arrivalTime, this.DAY_FORMAT, true);
    }

    get isSameDayOfArrival(): boolean | null {
        const { departureDay, arrivalDay } = this;
        if (departureDay === '--' || arrivalDay === '--') {
            return null;
        }
        return isSameDay(departureDay, arrivalDay);
    }

    get duration(): [number, number, number] {
        const { departureTime, arrivalTime } = this;
        const diffMinutes = dayjs(arrivalTime).diff(departureTime, 'minute');
        return formatMinutes(diffMinutes);
    }

    get arrivalNextDays(): number {
        const [days] = this.duration;
        if (this.isSameDayOfArrival === false) {
            return days === 0 ? 1 : days;
        }
        return 0;
    }

    get scheduleText(): string {
        const {
            departureTime,
            departureTimeTitle,
            arrivalTime,
            arrivalTimeText,
        } = this || {};
        if (!departureTime || !arrivalTime) {
            return '--';
        }
        return `${departureTimeTitle} - ${arrivalTimeText}`;
    }
}
