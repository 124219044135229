import { FC, useEffect, useState } from 'react';
import {
    Button,
    Fineprint,
    Panel,
    PanelContent,
    PanelHeader,
} from '@flixbus/honeycomb-react';
import { Icon, IconQuestion } from '@flixbus/honeycomb-icons-react';
import { RebookStatus } from '../types';
import { useTranslate } from '../../system/i18n/useTranslate';

const StatusCell: FC<{ item: RebookStatus }> = ({ item }) => {
    const translate = useTranslate();
    const { status, response, orderId } = item;
    const [isOpen, setPanel] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        if (status === 'ERROR') {
            const responseBody = response?.match(/{(.*?)}/);
            let errorMessage: string | null;
            try {
                errorMessage = responseBody?.[0]
                    ? JSON.parse(responseBody?.[0]).message.replace('/', '')
                    : response;
            } catch {
                errorMessage = translate('invalid_error_parsed');
            }

            setErrorMessage(errorMessage || '');
        }
    }, [response, status, translate]);

    if (status === 'ERROR') {
        return (
            <>
                <span style={{ textTransform: 'uppercase' }}>
                    {translate('not_rebooked')}
                </span>
                <Button
                    link
                    onClick={() => {
                        setPanel(true);
                    }}
                >
                    <Icon appearance="primary" InlineIcon={IconQuestion} />
                </Button>
                <Fineprint>{errorMessage}</Fineprint>
                {isOpen && (
                    <Panel
                        id={`${orderId}-error-panel`}
                        active={isOpen}
                        hasOverlay
                        onOverlayClick={() => {
                            setPanel(false);
                        }}
                    >
                        <PanelHeader
                            closeButtonProps={{
                                label: 'close',
                                onClick: () => {
                                    setPanel(false);
                                },
                            }}
                        >
                            {orderId}
                        </PanelHeader>
                        <PanelContent>{response}</PanelContent>
                    </Panel>
                )}
            </>
        );
    }
    return <>{status}</>;
};

export default StatusCell;
