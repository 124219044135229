import {
    GroupByTrip,
    SuggestionByTrip,
} from '../../dataLayer/classes/OrdersCollection';
import { Section } from '../Layout';
import { OrderBoxSkeleton } from '../OrderBox';
import OrdersGroup from './OrdersGroup';
import { Trip } from '../types';
import { useEffect } from 'react';
import { useAppState } from '../../dataLayer/AppState';
import {
    SELECTIONS_CLEAR,
    TRACKER_REMOVE,
} from '../../dataLayer/AppState/actions';
import useStatus from '../../dataLayer/useStatus';

export type TripInfoBase = Pick<
    Trip,
    | 'arrivalTime'
    | 'departureTime'
    | 'startStationName'
    | 'destinationStationName'
    | 'startStationId'
    | 'destinationStationId'
    | 'id'
>;
interface OrderList {
    rideId: string;
    isLoading: boolean;
    isFetching: boolean;
    groupByTrip?: GroupByTrip;
    suggestionsByTrip?: SuggestionByTrip;
    getGroupTripInfo?: (group: string) => TripInfoBase;
}

export default function OrderBatchList({
    rideId,
    isLoading,
    isFetching,
    groupByTrip,
    suggestionsByTrip,
    getGroupTripInfo,
}: OrderList) {
    const [, dispatch] = useAppState();
    const { data: statusData } = useStatus(rideId);

    useEffect(() => {
        dispatch(SELECTIONS_CLEAR);
        dispatch(TRACKER_REMOVE);
    }, [dispatch]);

    if (isLoading || isFetching) {
        return (
            <Section>
                <OrderBoxSkeleton />
            </Section>
        );
    }
    if (groupByTrip === undefined) {
        return null;
    }

    return (
        <>
            {Array.from(groupByTrip)?.map(([group, orders], index) => {
                if (getGroupTripInfo === undefined) {
                    return null;
                }
                return (
                    <OrdersGroup
                        rideId={rideId}
                        key={group}
                        groupKey={group}
                        orders={Array.from(orders).map(([, order]) => order)}
                        initSuggestions={
                            suggestionsByTrip
                                ? Array.from(
                                      suggestionsByTrip.get(group) || []
                                  ).map(([, suggestion]) => suggestion)
                                : undefined
                        }
                        groupOrderInfo={getGroupTripInfo(group)}
                        expanded={index === 0}
                        statuses={statusData}
                    />
                );
            })}
        </>
    );
}
