import { ChangeEvent, ReactNode } from 'react';
import { Box, Checkbox } from '@flixbus/honeycomb-react';
import classNames from 'classnames';

import OptionIC from './OptionIC';
import DurationAndDifference from './../DurationAndDifference';

import useDisabledSuggestion from '../DisabledSuggestionIcon/useDisabledSuggestion';
import OptionConnection from './OptionConnection';

import './OptionCard.scss';
import RideUid from '../RideUid';

import CapacityBlock from '../CapacityBlock';
import { Suggestion, CustomSuggestion } from '../../dataLayer/classes/Trip';
import { ReplaceTrip } from '../../dataLayer/AppState/types';

export type SelectedOption = {
    orderId: string | number;
    option: ReplaceTrip;
    suggestion?: Suggestion;
};
export interface OptionCardProps {
    orderId: string | number;
    originTripId: string;
    legacyOriginTripId?: string;
    suggestion?: Suggestion;
    customSuggestion?: CustomSuggestion;
    selected: boolean;
    altLabel: string | ReactNode;
    altLabelShift?: ReactNode;
    onSelect: (arg0: SelectedOption) => void;
    originalArrival: string;
    originalDeparture: string;
    selectedStop?: string;
    originTicketId: string;
}

export default function OptionCard(props: OptionCardProps) {
    const {
        orderId,
        legacyOriginTripId,
        originTripId: originTripUuid,
        suggestion,
        customSuggestion,
        selected,
        altLabel,
        altLabelShift,
        onSelect,
        originalArrival,
        originalDeparture,
        originTicketId,
    } = props;

    const [disabledSuggestionIcon, showDisabledMessage] = useDisabledSuggestion(
        orderId,
        suggestion?.uid,
        suggestion?.disabled
    );

    const displaySuggestion = customSuggestion || suggestion;

    if (displaySuggestion) {
        const isError =
            displaySuggestion.gettingStatus === 'ERROR' ? true : false;

        if (isError) {
            return (
                <Box
                    small
                    extraClasses="rbk-option-card rbk-option-card--danger"
                >
                    {displaySuggestion.gettingMessage}
                </Box>
            );
        }

        const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
            if (e.target.checked) {
                showDisabledMessage();
            }
            onSelect({
                orderId,
                option: {
                    originTripId: legacyOriginTripId,
                    originTripUuid,
                    replaceTripId: displaySuggestion.legacyUid,
                    replaceTripUuid: displaySuggestion.uid,
                },
                suggestion: displaySuggestion,
            });
        };

        const cardCss = classNames('rbk-option-card', {
            'rbk-option-card--active': selected,
            'rbk-zebra-bg--warning': displaySuggestion?.disabled,
        });

        const rideUid = displaySuggestion.icInfo.map((r) => {
            const { rideUid, rideUuid, lineColor } = r;
            return (
                <RideUid
                    key={`ride-uid-${rideUid}-${lineColor}`}
                    rideUuid={rideUuid}
                    rideUid={rideUid}
                    color={lineColor || null}
                />
            );
        });

        return (
            <Box small extraClasses={cardCss}>
                <div className="rbk-option-card__inner">
                    <Checkbox
                        id={`${orderId}-${displaySuggestion.uid}`}
                        small
                        aria-label="options-card"
                        value="option"
                        onChange={onChange}
                        checked={selected}
                    />
                    {disabledSuggestionIcon}
                    <DurationAndDifference
                        arrivalTime={displaySuggestion.arrivalTime}
                        departureTime={displaySuggestion.departureTime}
                        originalArrival={originalArrival}
                        originalDeparture={originalDeparture}
                        extraClasses="rbk-option-card__durr-and-diff"
                    />
                </div>

                <OptionConnection
                    departureTime={displaySuggestion.departureTime}
                    arrivalTime={displaySuggestion.arrivalTime}
                    startStationName={displaySuggestion.startStationName}
                    destinationStationName={
                        displaySuggestion.destinationStationName
                    }
                    isCustom={customSuggestion?.isCustom}
                    breakingPoint={customSuggestion?.breakingPoint}
                />

                <div className="rbk-option-card__bottom">
                    {displaySuggestion.suggestionTripType === 'DIRECT' ? (
                        <div>{rideUid}</div>
                    ) : (
                        <OptionIC
                            rides={displaySuggestion.icInfo}
                            originTicketId={originTicketId}
                        />
                    )}
                    {displaySuggestion.capacity !== undefined && (
                        <CapacityBlock
                            seats={displaySuggestion.capacity.seats}
                            slots={displaySuggestion.capacity.slots}
                        />
                    )}
                </div>
                {altLabelShift && (
                    <div className="rbk-option-card__label-shift">
                        {altLabelShift}
                    </div>
                )}
            </Box>
        );
    }

    return (
        <Box extraClasses="rbk-option-card rbk-option-card--alt-label">
            {altLabel}
        </Box>
    );
}
