import { useMutation } from '@tanstack/react-query';
import { api } from '../api';
import { ReplaceTrip } from '../dataLayer/AppState/types';
export interface RebookOption extends ReplaceTrip {
    orderId: number;
}
export interface Payload {
    notify: boolean;
    replaceTrips: RebookOption[];
}

export default function useRebook(rideId: string, defaults?: object): any {
    return useMutation({
        mutationFn: (payload: Payload) => {
            return api
                .post(`replaceTripQueue/ride/${rideId}`, payload)
                .then((response) => response.data)
                .catch((e) => {
                    if (e.response.data.error && e.response.data.errorMsg) {
                        throw new Error(
                            `${e.response.data.error}: ${e.response.data.errorMsg}`
                        );
                    }
                    throw new Error(`${e.code}: ${e.message}`);
                });
        },
        retry: 0,
        ...defaults,
    });
}
