import {
    Pager,
    PagerItem,
    PagerArrow,
    Select,
    SelectOption,
} from '@flixbus/honeycomb-react';

export const DEFAULT_PER_PAGE = 20;
const RESULTS_PER_PAGE = [10, 20, 30];

export interface PaginationProps {
    totalPages: number[];
    currentPage: number;
    currentSize: number;
    isPreviousData: boolean;
    onPageChange?: (page: number) => void;
    onPageSizeChange?: (size: number) => void;
}

const Pagination = ({
    totalPages,
    currentPage,
    currentSize = DEFAULT_PER_PAGE,
    isPreviousData,
    onPageChange = (page: number) => {},
    onPageSizeChange = (size: number) => {},
}: PaginationProps) => {
    const onClick = (page: number) => {
        if (!isPreviousData) {
            onPageChange(page);
        }
    };

    const onSelect = (size: number) => {
        onPageChange(0);
        onPageSizeChange(size);
    };

    const pagination = (
        <>
            {totalPages.map((page: number) => (
                <PagerItem
                    key={page}
                    active={currentPage === page}
                    onClick={() => {
                        if (!isPreviousData) {
                            onClick(page);
                        }
                    }}
                >
                    {page + 1}
                </PagerItem>
            ))}
        </>
    );

    return (
        <div className="rbk-pagination">
            <Pager aria-label="Pagination">
                <PagerArrow
                    aria-label="Previous Page"
                    disabled={currentPage === 0}
                    side="prev"
                    onClick={() => {
                        if (currentPage !== 0) {
                            onClick(currentPage - 1);
                        }
                    }}
                />
                {pagination}
                <PagerArrow
                    aria-label="Next Page"
                    disabled={
                        isPreviousData || currentPage + 1 === totalPages.length
                    }
                    side="next"
                    onClick={() => {
                        if (currentPage + 1 !== totalPages.length) {
                            onClick(currentPage + 1);
                        }
                    }}
                />
            </Pager>
            <Select
                id="results-on-page"
                aria-label="results on a page"
                onChange={(e) => onSelect(Number(e.target.value))}
                value={currentSize}
            >
                {RESULTS_PER_PAGE.map((results: number) => (
                    <SelectOption value={results} key={results}>
                        {results}
                    </SelectOption>
                ))}
            </Select>
        </div>
    );
};

export default Pagination;
