import { useState, useMemo, useEffect, useCallback } from 'react';
import {
    Infobox,
    Button,
    Grid,
    GridCol,
    NavHorizontal,
    NavItem,
} from '@flixbus/honeycomb-react';
import {
    Route,
    useLocation,
    useParams,
    useRouteMatch,
    NavLink,
    Redirect,
} from 'react-router-dom';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';

import { Section, InfoStrip } from '../Layout';
import OriginalRideInfo from '../OriginalRideInfo';
import useOpenerData from '../../hoocs/useOpenerData';
import useRideInfo from '../../dataLayer/useRideInfo';
import usePaxStatuses from '../../dataLayer/usePaxStatuses';
import { useAppState } from '../../dataLayer/AppState';
import { PAX_STATUSES_SET } from '../../dataLayer/AppState/actions';
import { RebookSummary } from '../Steps';
import RebookError from '../Steps/RebookError';
import RebookStatus from '../Steps/RebookStatus';
import { StatusInline } from '../RebookStatus';
import RebookQueue from '../Steps/RebookQueue';
import { Pagination, DEFAULT_PER_PAGE } from '../Pagination';
import { PaxStatusesByRideUuidResponse, PaxTicketsByRideUuid } from '../types';
import InvalidOrdersList from './InvalidOrdersList';
import OrderSingleList from './OrdersSingle';
import OrderListBatch from './OrdersBatchList';
import { Icon, IconDocument } from '@flixbus/honeycomb-icons-react';
import OrdersCollection from '../../dataLayer/classes/OrdersCollection';
import Order from '../../dataLayer/classes/Order';
import Trip from '../../dataLayer/classes/Trip';

import useOrders from '../../dataLayer/useOrders';

interface LocationState {
    orders: number[] | null;
}

const RideRebooking = () => {
    const translate: Translate = useTranslate();
    const orders = useOpenerData();
    const { rideId } = useParams<{ rideId: string }>();
    const location = useLocation<LocationState>();
    const match = useRouteMatch();
    const [currentPage, setCurrentPage] = useState(0);
    const [resultsPerPage, setResultsPerPage] = useState(DEFAULT_PER_PAGE);
    const [appState, dispatch] = useAppState();
    const {
        options: selectedOptions = {},
        isGhost,
        ghostMeta,
        paxStatuses: paxStatusesState = {},
    } = appState;
    const ride = useRideInfo(rideId, ghostMeta || orders !== null);
    const options = useOrders(rideId, orders || []);
    const { data, isLoading, isFetching, isPreviousData } = options;
    data?.setPageSize(resultsPerPage);
    const rideOrders = data?.getPage(currentPage);
    const invalidRideOrders = rideOrders?.invalidOrders;
    const validRideOrders = rideOrders;
    const groupByTrip = rideOrders?.byTrip;
    const suggestionsByTrip = rideOrders?.suggestionsByTrip;
    const getGroupTripInfo = rideOrders?.getGroupTripInfo;
    /**
     * @todo move this implementation to the OrderCollection
     */
    let statusesRequestsIDs: PaxTicketsByRideUuid = useMemo(() => {
        let ids: any = {};
        validRideOrders?.forEach((order: Order) => {
            const FULL: Trip | undefined = order?.trip;

            if (FULL) {
                let ticketId: string;
                if (order?.orderDetails?.passengers[0]?.ticketId) {
                    ticketId = order.orderDetails.passengers[0].ticketId;
                }

                FULL?.icInfo?.forEach((icInfo) => {
                    const { rideUuid } = icInfo;
                    // add ics UUID
                    if (!ids[rideUuid]) {
                        ids[rideUuid] = [];
                        ids[rideUuid].push(ticketId);
                    } else {
                        // add ticketId to the ics
                        if (!ids[rideUuid].includes(ticketId)) {
                            ids[rideUuid].push(ticketId);
                        }
                    }
                });
            }
        });

        return ids || {};
    }, [validRideOrders]);

    // Pagination and "loading" statuses

    const totalPages: number[] = data?.totalPages
        ? Array.from(Array(data?.totalPages).keys())
        : [];

    useEffect(() => {
        if (data !== null) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }, [currentPage, resultsPerPage, data]);

    // Now perform non render blocking the pax status request
    const {
        data: paxStatuses,
        isFetching: paxStatusesFetching,
    }: PaxStatusesByRideUuidResponse = usePaxStatuses(statusesRequestsIDs, {
        enabled: Object.keys(statusesRequestsIDs).length > 0,
        keepPreviousData: true,
    });

    useEffect(() => {
        // save pax status to the state

        if (
            Object.keys(statusesRequestsIDs).length > 0 &&
            Object.keys(paxStatusesState).length === 0 &&
            paxStatusesFetching === false
        ) {
            dispatch(PAX_STATUSES_SET, paxStatuses);
        }
    }, [
        dispatch,
        paxStatuses,
        paxStatusesState,
        statusesRequestsIDs,
        paxStatusesFetching,
    ]);

    const redirectView = useCallback(() => {
        if (orders === undefined) {
            return null;
        }
        /**
         * @todo sort it out because any other routes redirects here even access point
         */
        if (orders === null || orders.length === 0 || orders.length > 2) {
            return <Redirect to={`/${rideId}/batch`} />;
        }
        return null;
    }, [orders, rideId]);
    return (
        <div>
            {redirectView()}
            <Route path={[match.path, `${match.path}/batch`]} exact>
                <Section>
                    <Grid justify="right">
                        <GridCol>
                            <OriginalRideInfo
                                lineCode={ride.data?.lineCode}
                                tripNumber={ride.data?.tripNumber}
                                isGhost={isGhost}
                                showSkeleton={ride.isLoading}
                                error={ride.isError ? String(ride.error) : ''}
                            />
                        </GridCol>
                        <GridCol inline>
                            <NavHorizontal aria-label="Rebooking type navigation">
                                <NavItem
                                    to={`/${rideId}`}
                                    RouterLink={NavLink}
                                    exact
                                >
                                    {translate('single-rebooking-button')}
                                </NavItem>
                                <NavItem
                                    to={`/${rideId}/batch`}
                                    RouterLink={NavLink}
                                    exact
                                >
                                    {translate('batch-rebooking-button')}
                                </NavItem>
                            </NavHorizontal>
                        </GridCol>
                    </Grid>
                </Section>
                <InfoStrip>
                    <Grid>
                        <GridCol extraClasses="align-center">
                            {selectedOptions &&
                                `${translate('selected')}: ${
                                    Object.keys(selectedOptions).length
                                }`}
                            {rideOrders?.length
                                ? `, ${translate('results-on-page')} ${
                                      rideOrders.length
                                  }`
                                : ''}
                            {data?.length
                                ? `, ${translate('results-total')} ${
                                      data.length
                                  }`
                                : ''}
                        </GridCol>
                        <GridCol size={7}>
                            <Grid justify="right">
                                <GridCol inline>
                                    <StatusInline rideUuid={rideId} />
                                </GridCol>
                            </Grid>
                        </GridCol>
                    </Grid>
                </InfoStrip>

                {options.isError && (
                    <Section>
                        <Infobox appearance="danger">
                            {String(options.error)}
                        </Infobox>
                    </Section>
                )}

                <Section>
                    {invalidRideOrders?.length !== undefined && (
                        <InvalidOrdersList
                            orders={invalidRideOrders as OrdersCollection}
                        />
                    )}
                </Section>
                {totalPages?.length > 1 && (
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        currentSize={resultsPerPage}
                        isPreviousData={isPreviousData}
                        onPageChange={setCurrentPage}
                        onPageSizeChange={setResultsPerPage}
                    />
                )}
            </Route>
            <Route path={`${match.path}`} exact>
                <OrderSingleList
                    rideId={rideId}
                    groupByTrip={groupByTrip}
                    isLoading={isLoading}
                    isFetching={isFetching}
                />
            </Route>
            <Route path={`${match.path}/batch`} exact>
                <OrderListBatch
                    rideId={rideId}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    groupByTrip={groupByTrip}
                    suggestionsByTrip={suggestionsByTrip}
                    getGroupTripInfo={getGroupTripInfo}
                />
            </Route>
            <Route
                path={[`${match.path}/summary`, `${match.path}/batch/summary`]}
                exact
            >
                <RebookSummary
                    rideUuid={rideId}
                    currentPage={currentPage}
                    resultsPerPage={data?.pageSize || DEFAULT_PER_PAGE}
                />
            </Route>
            <Route path={`${match.path}/error`} exact>
                <RebookError rideUuid={rideId} />
            </Route>
            <Route path={`${match.path}/status`} exact>
                <RebookStatus rideUuid={rideId} />
            </Route>
            <Route path={`${match.path}/queue`} exact>
                <RebookQueue rideUuid={rideId} />
            </Route>
            <Route path={[match.path, `${match.path}/batch`]} exact>
                {Object.entries(selectedOptions).length > 0 && (
                    <div className="rbk-fixed-bottom-box">
                        <Grid justify="right">
                            <GridCol inline>
                                <Button
                                    appearance="primary"
                                    RouterLink={NavLink}
                                    to={`${location.pathname}/summary`}
                                >
                                    {translate('summary__rebook_orders')}
                                    {' | '}
                                    {Object.keys(selectedOptions).length}{' '}
                                    <Icon InlineIcon={IconDocument} />
                                </Button>
                            </GridCol>
                        </Grid>
                    </div>
                )}
                {totalPages?.length > 1 && (
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        currentSize={resultsPerPage}
                        isPreviousData={isPreviousData}
                        onPageChange={setCurrentPage}
                        onPageSizeChange={setResultsPerPage}
                    />
                )}
            </Route>
        </div>
    );
};

export default RideRebooking;
