import { useRef } from 'react';
import { Box } from '@flixbus/honeycomb-react';
import SuggestionTripCard from '../SuggestionTripCard/SuggestionTripCard';
import useScroll from '../../hoocs/useScroll';

import './Alternatives.scss';
import dayjs from 'dayjs';
import { parseTimezone, SHORT_DATE_FORMAT } from '../../utils';
import { useTranslate } from '../../system/i18n/useTranslate';
import {
    Suggestion,
    SuggestionsCollection,
} from '../../dataLayer/classes/Trip';

export interface AlternativesListProps {
    options: SuggestionsCollection;
    originTrip?: string;
    onSelect?: (suggestion: Suggestion) => void;
    dateSelected?: string;
    originalStartStationName: string;
    originalArrivalTime: string;
    originalDepartureTime: string;
    fastSuggestionsIds?: string[];
    originTicketId: string;
    selectedStop?: string;
    size?: 'full';
    limitOptionsTime?: string;
}

export default function AlternativesList({
    options,
    onSelect = () => {},
    dateSelected,
    originTrip,
    originalStartStationName,
    originalArrivalTime,
    originalDepartureTime,
    fastSuggestionsIds,
    originTicketId,
    selectedStop = '',
    size,
    limitOptionsTime,
}: AlternativesListProps) {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const scrollToRef = useRef<HTMLDivElement>(null);
    const translate = useTranslate();

    useScroll({ wrapperRef, scrollToRef });

    const filteredOptions = options.filter((option) => {
        if (option.gettingStatus === 'ERROR') {
            return false;
        }
        // hide options with missing departureTime and arrivalTime
        if (option.departureTime === null || option.arrivalTime === null) {
            return false;
        }
        // hide options for another day, injected original trip also match
        const formatDeparture = dayjs
            .utc(option.departureTime)
            .utcOffset(parseTimezone(option.departureTime))
            .format(SHORT_DATE_FORMAT);

        if (
            dateSelected &&
            !dayjs(dateSelected).isSame(formatDeparture, 'day')
        ) {
            return false;
        }

        return true;
    });

    if (filteredOptions.length === 0) {
        return <Box>{translate('no-alternatives')}</Box>;
    }

    let isLater = false;
    let cssClass = ['alternatives-scroll-wrapper'];
    if (size) {
        cssClass.push('alternatives-scroll-wrapper--full');
    }

    return (
        <div ref={wrapperRef} className={cssClass.join(' ')}>
            {filteredOptions.map((option) => {
                if (!isLater) {
                    isLater = option.uid === originTrip;
                }
                const isFastSuggestion = Boolean(
                    option.legacyUid &&
                        fastSuggestionsIds?.includes(option.legacyUid)
                );
                const isOriginal = option.uid === originTrip;
                const isLimitedByTime = limitOptionsTime
                    ? new Date(option.departureTime).getTime() -
                          new Date(limitOptionsTime).getTime() <
                      1800000
                    : false;

                return (
                    <SuggestionTripCard
                        key={option.legacyUid}
                        suggestion={option}
                        onSelect={onSelect}
                        original={isOriginal}
                        originalStartStationName={originalStartStationName}
                        originalArrival={originalArrivalTime}
                        originalDeparture={originalDepartureTime}
                        ref={option.uid === originTrip ? scrollToRef : null}
                        isLater={isLater}
                        isFastSuggestion={isFastSuggestion}
                        originTicketId={originTicketId}
                        selectDisabled={
                            Boolean(option.uid) &&
                            !option.uid.includes(selectedStop)
                        }
                        isLimitedByTime={isLimitedByTime}
                    />
                );
            })}
        </div>
    );
}
