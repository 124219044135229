import { forwardRef } from 'react';
import classNames from 'classnames';
import { Button, Tooltip, Tag, Fineprint } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconBus,
    IconSeat,
    IconBike,
    IconBikeNo,
    IconBusStop,
    IconTransfer,
} from '@flixbus/honeycomb-icons-react';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';
import { Suggestion } from '../../dataLayer/classes/Trip';
import DurationAndDifference from '../DurationAndDifference';

import './SuggestionTripCard.scss';
import useDisabledSuggestion from '../DisabledSuggestionIcon/useDisabledSuggestion';
import OptionIC from '../OptionCard/OptionIC';
import RideUid from '../RideUid';

export interface SuggestionTripCardProps {
    /** Suggestion trip object */
    suggestion: Suggestion;
    original?: boolean;
    onSelect?: (suggestion: Suggestion) => void;
    originalStartStationName: string;
    originalArrival: string;
    originalDeparture: string;
    passRef?: HTMLDivElement;
    isLater?: boolean;
    isFastSuggestion?: boolean;
    originTicketId: string;
    selectDisabled: boolean;
    isLimitedByTime?: boolean;
}

const SuggestionTripCard = forwardRef<HTMLDivElement, SuggestionTripCardProps>(
    (
        {
            suggestion,
            original,
            onSelect = () => {},
            originalStartStationName,
            originalArrival,
            originalDeparture,
            isLater,
            isFastSuggestion,
            originTicketId,
            selectDisabled,
            isLimitedByTime = false,
        }: SuggestionTripCardProps,
        ref
    ) => {
        const {
            capacity,
            suggestionTripType,
            startStationName,
            destinationStationName,
            departureTime,
            arrivalTime,
            icInfo,
            uid,
            disabled,
            duration,
            departureTimeText,
            arrivalTimeText,
            departureTimeDayTitle,
        } = suggestion;

        const [disabledSuggestion, showNotification] = useDisabledSuggestion(
            undefined,
            uid,
            disabled,
            'text'
        );

        const translate: Translate = useTranslate();
        const TYPE = {
            DIRECT: translate('direct'),
            INTERCONNECTION: translate('interconnection'),
        };
        const cardCss = classNames('suggestion-trip-card', {
            'suggestion-trip-card--original': original,
            'rbk-zebra-bg--warning': disabled,
            'suggestion-trip-card--later': isLater,
        });

        return (
            <>
                <div className={cardCss} ref={ref}>
                    {disabledSuggestion}
                    {isLimitedByTime && (
                        <div>
                            <Icon
                                InlineIcon={IconTransfer}
                                style={{ fill: 'var(--rv-danger-color)' }}
                            />{' '}
                            <Fineprint>
                                {translate('suggestion.impossible-connection')}
                            </Fineprint>
                        </div>
                    )}
                    <div>{departureTimeDayTitle}</div>
                    <div className="suggestion-trip-card__main">
                        <div className="suggestion-trip-card__left">
                            <div className="suggestion-trip-card__info">
                                <div className="suggestion-trip-card__info-from-to">
                                    <time className="trip-card-time">
                                        {departureTimeText}
                                    </time>
                                    <div className="suggestion-trip-card__info-name">
                                        {originalStartStationName !==
                                            startStationName && (
                                            <Tooltip
                                                id={`${uid}-tooltip`}
                                                content={translate(
                                                    'different_station'
                                                )}
                                                position="top"
                                                openOnHover
                                                extraClasses="different-station-tooltip"
                                            >
                                                <Icon
                                                    InlineIcon={IconBusStop}
                                                    extraClasses="different-station"
                                                    title={translate(
                                                        'different_station'
                                                    )}
                                                />
                                            </Tooltip>
                                        )}
                                        {startStationName}
                                    </div>
                                </div>
                                <div className="suggestion-trip-card__info-duration trip-card-duration">
                                    {duration.length > 0
                                        ? `${duration[1]}h ${duration[2]}min`
                                        : null}
                                </div>
                                <div className="suggestion-trip-card__info-from-to">
                                    <time className="trip-card-time">
                                        {arrivalTimeText}
                                    </time>
                                    <div className="suggestion-trip-card__info-name">
                                        {destinationStationName}
                                    </div>
                                </div>
                            </div>
                            {!original && (
                                <div className="suggestion-trip-card__type">
                                    <div className="suggestion-trip-card__type-inner">
                                        {suggestionTripType === 'DIRECT' && (
                                            <>
                                                <div className="suggestion-trip-card__type-icons">
                                                    <Icon
                                                        InlineIcon={IconBus}
                                                    />
                                                </div>
                                                <RideUid
                                                    rideUuid={
                                                        icInfo[0].rideUuid
                                                    }
                                                    rideUid={icInfo[0].rideUid}
                                                    color={
                                                        icInfo[0]?.lineColor ||
                                                        null
                                                    }
                                                />{' '}
                                                &nbsp;
                                            </>
                                        )}

                                        {suggestionTripType ===
                                        'INTERCONNECTION' ? (
                                            <OptionIC
                                                rides={icInfo}
                                                originTicketId={originTicketId}
                                            />
                                        ) : (
                                            TYPE[suggestionTripType || 'DIRECT']
                                        )}
                                    </div>

                                    <DurationAndDifference
                                        arrivalTime={arrivalTime}
                                        departureTime={departureTime}
                                        originalArrival={originalArrival}
                                        originalDeparture={originalDeparture}
                                        extraClasses="suggestion-trip-card__time-diff"
                                    />
                                </div>
                            )}
                        </div>

                        {original ? (
                            <Tag>{translate('original-trip')}</Tag>
                        ) : (
                            <>
                                <div className="suggestion-trip-card__right">
                                    {capacity && (
                                        <div className="suggestion-trip-card__capacity">
                                            <span>
                                                <Icon InlineIcon={IconSeat} />{' '}
                                                {capacity.seats}
                                            </span>
                                            <span
                                                className={
                                                    capacity.slots < 0
                                                        ? 'danger'
                                                        : ''
                                                }
                                            >
                                                <Icon
                                                    InlineIcon={
                                                        capacity.slots < 0
                                                            ? IconBikeNo
                                                            : IconBike
                                                    }
                                                />{' '}
                                                {capacity.slots}
                                            </span>
                                        </div>
                                    )}
                                    {!isFastSuggestion && (
                                        <Button
                                            appearance="secondary"
                                            title={
                                                originalStartStationName !==
                                                startStationName
                                                    ? translate(
                                                          'different_station'
                                                      )
                                                    : translate('select')
                                            }
                                            disabled={
                                                originalStartStationName !==
                                                    startStationName &&
                                                selectDisabled
                                            }
                                            onClick={() => {
                                                showNotification();
                                                onSelect(suggestion);
                                            }}
                                        >
                                            {translate('select')}
                                        </Button>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    {!original && isFastSuggestion && (
                        <div
                            className={
                                isLater ? 'ribbon ribbon--right' : 'ribbon'
                            }
                        >
                            <span>
                                {isLater
                                    ? translate('later')
                                    : translate('earlier')}
                            </span>
                        </div>
                    )}
                </div>
            </>
        );
    }
);

export default SuggestionTripCard;
