import {
    Box,
    Checkbox,
    Grid,
    GridCol,
    Heading,
    Infobox,
    Link,
} from '@flixbus/honeycomb-react';
import {
    Icon,
    IconArrowBigRight,
    IconArrowDown,
    IconArrowUp,
    IconDot,
    IconNewTab,
} from '@flixbus/honeycomb-icons-react';
import classNames from 'classnames';
import { Section } from '../Layout';
import { StatusResponse } from '../types';
import Order from '../../dataLayer/classes/Order';
import { useTranslate } from '../../system/i18n/useTranslate';
import { useAppState } from '../../dataLayer/AppState';
import {
    ALTERNATIVES_ADD_BATCH,
    BATCH_OPTION_ADD,
    BATCH_ORDER_ADD,
    BATCH_ORDER_TOGGLE,
    OPTION_ADD,
    SINGLE_OPTION_SET,
    TRACKER_ADD,
    TRACKER_REMOVE,
} from '../../dataLayer/AppState/actions';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AlternativesList from '../Alternatives/AlternativesList';
import DatePicker from '../DatePicker';
import { TripInfoBase } from './OrdersBatchList';
import dayjs from 'dayjs';
import { SHORT_DATE_FORMAT, parseTimezone } from '../../utils';
import useGroupSuggestions from '../../dataLayer/useGroupSuggestions';
import AlternativesSkeleton from '../Alternatives/AlternativesSkeleton';
import {
    Alternatives,
    BatchOption,
    Options,
    SingleOptions,
} from '../../dataLayer/AppState/types';

import RebookingStatusTooltip from './RebookingStatusTooltip';
import { ORDER_BV_URL } from '../../config/const';

import useUserCan from '../../auth/useUserCan';

import './OrdersGroup.scss';
import StopsSelector from '../StopsSelector';

import useBatchAltTripTracking from '../../DataDog/useBatchAltTripTracking';
import { NotificationContext } from '../../system/Notification';
import { Suggestion } from '../../dataLayer/classes/Trip';

interface OrdersGroupProps {
    rideId: string;
    groupKey: string;
    orders: Order[];
    initSuggestions?: Suggestion[];
    groupOrderInfo: TripInfoBase;
    expanded?: boolean;
    statuses?: StatusResponse;
}

export default function OrdersGroup({
    rideId,
    orders,
    groupKey,
    initSuggestions,
    groupOrderInfo,
    expanded = false,
    statuses,
}: OrdersGroupProps) {
    const translate = useTranslate();
    const [appState, dispatch] = useAppState();
    const [stops, selectStations] = useState<any[]>([]);
    const [stopFrom, stopTo] = stops;

    const [rebookingStatuses, setRebookingStatuses] = useState<any>({});

    const isUserCanSearch = useUserCan('trip_search');

    const notify = useContext(NotificationContext);

    useEffect(() => {
        if (statuses && Object.entries(statuses).length) {
            const statusesList = statuses.body.reduce((acc, rebookStatus) => {
                return {
                    ...acc,
                    [String(rebookStatus.orderId)]: rebookStatus.status,
                };
            }, {});
            setRebookingStatuses(statusesList);
        }
    }, [statuses]);

    const { batchOrders } = appState;
    const isInit = useRef<boolean>(true);

    const departureTime =
        groupOrderInfo && groupOrderInfo.departureTime !== null
            ? dayjs
                  .utc(groupOrderInfo.departureTime)
                  .utcOffset(parseTimezone(groupOrderInfo.departureTime))
                  .startOf('day')
                  .add(12, 'hour')
                  .valueOf()
            : new Date().getTime();

    const [selectedDate, selectDate] = useState(departureTime);
    const [isGroupExpanded, setIsGroupExpanded] = useState(expanded);

    const ordersIds = orders.map((o) => o.orderId);

    const tracker = useBatchAltTripTracking(groupKey, ordersIds as number[]);

    const stopsParams = useCallback(() => {
        if ([stopFrom, stopTo].includes(undefined)) {
            return [
                groupOrderInfo.startStationId,
                groupOrderInfo.destinationStationId,
            ];
        }
        return [stopFrom.id, stopTo.id];
    }, [
        stopFrom,
        stopTo,
        groupOrderInfo.startStationId,
        groupOrderInfo.destinationStationId,
    ]);

    const {
        data: suggestions,
        isFetching,
        isError,
        error,
    } = useGroupSuggestions(
        rideId,
        ordersIds,
        selectedDate / 1000,
        stopsParams(),
        {
            //enabled: enableSuggestionsRequest(),
            retryOnMount: false,
            staleTime: 36000,
        }
    );

    const formattedSelectedDate = dayjs(selectedDate).format(SHORT_DATE_FORMAT);

    useEffect(() => {
        if (isInit.current) {
            dispatch(BATCH_ORDER_ADD, ordersIds);
            isInit.current = false;
        }
    }, [dispatch, ordersIds, batchOrders]);

    useEffect(() => {
        if (tracker) {
            const altTrip =
                stopFrom && stopTo ? `${stopFrom.title}-${stopTo.title}` : '';
            tracker.track('search', {
                altTrip,
                selectedDate: formattedSelectedDate,
            });
        }
    }, [stopFrom, stopTo, tracker, formattedSelectedDate]);

    function onSelect(suggestion: Suggestion) {
        let alternatives: Alternatives = {};
        let options: Options = {};
        let singleOptions: SingleOptions = {};
        let batchOptions: BatchOption = {
            orderIds: orders.map((o) => o.orderId),
            group: groupKey,
            suggestion,
            option: {
                replaceTripId: suggestion.legacyUid,
                replaceTripUuid: suggestion.uid,
                originTripId: orders[0].legacyOriginTripId,
                originTripUuid: orders[0].originTripId,
            },
        };
        orders.forEach((order) => {
            if (batchOrders?.includes(order.orderId as number)) {
                alternatives[String(order.orderId)] = suggestion;
                options[String(order.orderId)] = {
                    originTripId: order.legacyOriginTripId,
                    originTripUuid: order.originTripId,
                    replaceTripId: suggestion.legacyUid,
                    replaceTripUuid: suggestion.uid,
                };
                singleOptions[String(order.orderId)] = {
                    orderId: order.orderId,
                    option: {
                        originTripId: order.legacyOriginTripId,
                        originTripUuid: order.originTripId,
                        replaceTripId: suggestion.legacyUid,
                        replaceTripUuid: suggestion.uid,
                    },
                    suggestion,
                    order,
                };
            }
        });

        dispatch(OPTION_ADD, options);
        dispatch(ALTERNATIVES_ADD_BATCH, alternatives);
        dispatch(BATCH_OPTION_ADD, batchOptions);
        dispatch(SINGLE_OPTION_SET, singleOptions);

        notify({
            type: 'success',
            text: (
                <span>
                    {translate('notifications.option-add')} <br />
                    <small>
                        {`${suggestion.startStationName} - ${suggestion.destinationStationName}`}
                    </small>
                </span>
            ),
            duration: 8000,
        });

        if (tracker) {
            dispatch(TRACKER_ADD, tracker);
            const altTrip =
                stopFrom && stopTo
                    ? `${suggestion.startStationName}-${suggestion.destinationStationName}`
                    : '';
            tracker.track('select', {
                altTrip,
                selectedDate: formattedSelectedDate,
            });
        }
    }

    function renderOptions() {
        if (isFetching) {
            return <AlternativesSkeleton />;
        }
        if (isError) {
            return (
                <Infobox appearance="danger" small>
                    {String(error)}
                </Infobox>
            );
        }
        if (initSuggestions && groupOrderInfo) {
            return (
                <AlternativesList
                    options={suggestions || initSuggestions}
                    originalArrivalTime={groupOrderInfo.arrivalTime}
                    originalDepartureTime={groupOrderInfo.departureTime}
                    originalStartStationName={groupOrderInfo.startStationName}
                    originTicketId=""
                    originTrip={groupOrderInfo.id}
                    onSelect={onSelect}
                    size="full"
                    dateSelected={formattedSelectedDate}
                />
            );
        }
    }

    const groupExpandClass = classNames('orders-group__expand-collapse', {
        'orders-group__expand-collapse--expanded': isGroupExpanded,
    });

    function onSelectStops([from, to]: any[]) {
        if (from === undefined && to === undefined && tracker) {
            dispatch(TRACKER_REMOVE, tracker);
            tracker.track('reset');
        }
        selectStations([from, to]);
    }

    return (
        <Section>
            <Box extraClasses="orders-group">
                <Heading
                    size={3}
                    extraClasses="orders-group__heading"
                    onClick={() => setIsGroupExpanded(!isGroupExpanded)}
                >
                    {translate('orders', orders.length)}{' '}
                    <span>
                        {groupOrderInfo.startStationName}{' '}
                        <Icon InlineIcon={IconArrowBigRight} size={2} />{' '}
                        {groupOrderInfo.destinationStationName}
                        {Object.keys(rebookingStatuses).some((id) =>
                            ordersIds.includes(Number(id))
                        ) && (
                            <Icon
                                InlineIcon={IconDot}
                                style={{ fill: 'red' }}
                            />
                        )}
                    </span>
                    <Icon
                        InlineIcon={
                            isGroupExpanded ? IconArrowUp : IconArrowDown
                        }
                        extraClasses="orders-group__arrow"
                        size={4}
                    />
                </Heading>
                <div className={groupExpandClass}>
                    <ul className="orders-group__list">
                        {orders?.map((order: Order) => {
                            return (
                                <li key={order.orderId}>
                                    <Checkbox
                                        id={`${order.orderId}-batch-orders-group`}
                                        label={String(order.orderId)}
                                        checked={Boolean(
                                            batchOrders?.includes(
                                                order.orderId as number
                                            )
                                        )}
                                        small
                                        value={order.orderId}
                                        onChange={() => {
                                            dispatch(
                                                BATCH_ORDER_TOGGLE,
                                                order.orderId
                                            );
                                        }}
                                    />
                                    <Link
                                        href={ORDER_BV_URL.replace(
                                            '{orderId}',
                                            String(order.orderId)
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Icon InlineIcon={IconNewTab} />
                                    </Link>
                                    {rebookingStatuses[order.orderId] && (
                                        <RebookingStatusTooltip
                                            status={
                                                rebookingStatuses[order.orderId]
                                            }
                                        />
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </Box>
            <Box>
                <Grid justify="center">
                    <GridCol size={7}>
                        <Heading
                            className="orders-group-options--title"
                            size={3}
                        >
                            {translate('orders-group-options.title')}
                        </Heading>
                        {isUserCanSearch && (
                            <StopsSelector onSelect={onSelectStops} />
                        )}
                        <DatePicker
                            startDate={departureTime}
                            optionsCount={3}
                            onSelect={(timestamp) => {
                                selectDate(timestamp);
                            }}
                            utcOffset={
                                groupOrderInfo?.departureTime
                                    ? parseTimezone(
                                          groupOrderInfo?.departureTime
                                      )
                                    : 0
                            }
                        />
                        {renderOptions()}
                    </GridCol>
                </Grid>
            </Box>
        </Section>
    );
}
