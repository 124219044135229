import { api } from '../api';
import {
    QueryFunctionContext,
    useQuery,
    QueryObserverOptions,
    UseQueryResult,
} from '@tanstack/react-query';

import { SuggestionsCollection } from './classes/Trip';
type ResponseSuggestions = SuggestionsCollection;
function fetch({
    queryKey,
}: QueryFunctionContext): Promise<ResponseSuggestions> {
    const [, rideId, orderId, suggestionsTimestamp, from, to] = queryKey;
    const url: string = '/suggestions/pax/rebookingOptions';
    return api
        .post(url, {
            rideId,
            orderId,
            suggestionsTimestamp,
            fromStationId: from,
            toStationId: to,
        })
        .then((response) => new SuggestionsCollection(response.data.body))
        .catch((e: any) => {
            if (e.response.data?.error && e.response.data?.errorMsg) {
                throw new Error(
                    `${e.response.data.error}: ${e.response.data.errorMsg}`
                );
            }
            throw new Error(`${e.code}: ${e.message}`);
        });
}

export default function usePartialAlternatives(
    rideUuid: string,
    order: number | string,
    date: string | number,
    [from, to]: (string | undefined)[],
    options: QueryObserverOptions<ResponseSuggestions> = {}
): UseQueryResult<ResponseSuggestions> {
    return useQuery({
        queryKey: ['partialAlternatives', rideUuid, order, date, from, to],
        queryFn: fetch,
        ...options,
    });
}
