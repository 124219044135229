import { FC } from 'react';
import { Box, Button, Text } from '@flixbus/honeycomb-react';
import { useTranslate } from '../../system/i18n/useTranslate';
import { ORDER_BV_URL } from '../../config/const';
import OrdersCollection from '../../dataLayer/classes/OrdersCollection';

const InvalidOrdersList: FC<{ orders?: OrdersCollection }> = ({ orders }) => {
    const translate = useTranslate();
    if (orders === undefined || orders.length === 0) {
        return null;
    }
    return (
        <Box>
            {translate('Orders with missing data')}: {orders.length}
            <Text>
                {orders.map((order) => (
                    <Button
                        Elem="a"
                        key={order.orderId}
                        target="_blank"
                        href={ORDER_BV_URL.replace(
                            '{orderId}',
                            String(order.orderId)
                        )}
                        link
                    >
                        {order.orderId}
                    </Button>
                ))}
            </Text>
        </Box>
    );
};

export default InvalidOrdersList;
