import dayjs from 'dayjs';
import { FormatMinutes } from '../components/types';
import { Translate } from '../system/i18n/useTranslate';

export function parseTimezone(time: string): number {
    const regexp = /(\+|-)(\d{2})(\d{2})$/;
    const match = regexp.exec(time);
    if (match === null) {
        throw new Error(
            `${time} is invalid time format or doesn't contain proper time zone`
        );
    }
    const hours = Number(match[2]);
    const minutes = Number(match[3]);
    const sign = match[1];
    return (hours + minutes / 60) * (sign === '-' ? -1 : 1);
}

export const SHORT_DATE_FORMAT = 'YYYY-MM-DD';
export const SHORT_TIME_DATE_FORMAT = 'HH:mm, DD MMM YYYY';
export const FULL_DATE_TIME_FORMAT = 'ddd, DD MMM HH:mm';
export const FULL_DATE_FORMAT = 'ddd, DD MMM';
export const TIME_ONLY_FORMAT = 'HH:mm';
export const DAY_FORMAT = 'DD, MMM';

/**
 * format minutes to the human readable value 'Hours h minutes min'
 * @func formatMinutes
 * @param {string} min - minutes to be formated
 * @return {array} - [hours, minutes]
 */
export function formatMinutes(min: number): FormatMinutes {
    const days = Math.floor(min / 1440);
    const hours = Math.floor((min % 1440) / 60);
    const minutes = min % 60;

    return [days, hours, minutes];
}

/**
 * date time formatting
 * @func formatDateTime
 * @param {string} time - time stamp 2023-01-22T08:40:00+0100
 * @param {string} format - valid date time format
 * @param {string} tz - use timezone
 * @return  {string}
 */
export function formatDateTime(
    time: string,
    format: string,
    tz: boolean
): string {
    if (tz) {
        return dayjs.utc(time).utcOffset(parseTimezone(time)).format(format);
    }
    return dayjs(time).format(format);
}

/**
 * date time formatting
 * @func humanReadTimeString
 * @param {diff} number, number, number - 2, 12, 56 (days, hours, minutes)+
 * @return  {string} - 1d 12h 12m
 */
export const humanReadTimeString = (diff: FormatMinutes): string => {
    const [days, hours, minutes] = diff;
    const diffString = [];
    if (days > 0) {
        diffString.push(`${days}d`);
    }
    if (hours > 0) {
        diffString.push(`${hours}h`);
    }
    if (minutes > 0) {
        diffString.push(`${minutes}min`);
    }
    return diffString.join(' ');
};

/**
 * date diff to string formatting
 * @func getDiffReadableString
 * @param {string} original - time stamp 2023-01-22T08:40:00+0100
 * @param {string} toCompare - time stamp 2023-01-22T08:40:00+0100
 * @param {string} label - 'earlier' or 'later'
 * @return  {string} - 1d 12h 12m earlier
 */
export const getDiffReadableString = (
    original: string,
    toCompare: string,
    label?: string
): string | undefined => {
    if (original === null || toCompare === null) {
        return;
    }

    const isBefore = dayjs(original).isBefore(dayjs(toCompare));

    const durationDiff = formatMinutes(
        isBefore
            ? dayjs(toCompare).diff(original, 'minute')
            : dayjs(original).diff(toCompare, 'minute')
    );

    if (durationDiff) {
        const end = label ? ` ${label}` : '';
        const diff = durationDiff.some((num) => num > 0)
            ? humanReadTimeString(durationDiff) + `${end}`
            : '';
        return diff;
    }
};

/**
 * trip dates diff to string formatting
 * @func getTripsDurationDifference
 * @param {object} tripOne - { departureTime: time stamp 2023-01-22T08:40:00+0100; arrivalTime: time stamp 2023-01-22T08:40:00+0100 }
 * @param {object} tripTwo - { departureTime: time stamp 2023-01-22T08:40:00+0100; arrivalTime: time stamp 2023-01-22T08:40:00+0100 }
 * @return  {string, boolean} - 1d 12h 12m, true or false
 */
export const getTripsDurationDifference = (
    tripOne: { departureTime: string; arrivalTime: string },
    tripTwo: { departureTime: string; arrivalTime: string },
    translate: Translate
) => {
    const { departureTime: tripOneDeparture, arrivalTime: tripOneArrival } =
        tripOne;
    const { departureTime: tripTwoDeparture, arrivalTime: tripTwoArrival } =
        tripTwo;

    // get first trip duration
    const tripOneMinsDuration = dayjs(tripOneArrival).diff(
        tripOneDeparture,
        'minute'
    );
    // get second trip duration
    const tripTwoMinsDuration = dayjs(tripTwoArrival).diff(
        tripTwoDeparture,
        'minute'
    );

    // get duration difference
    const durationDifference: number =
        tripTwoMinsDuration > tripOneMinsDuration
            ? tripTwoMinsDuration - tripOneMinsDuration
            : tripOneMinsDuration - tripTwoMinsDuration;
    const minutesDuration = formatMinutes(durationDifference);

    // tripOne is faster
    const isFaster = tripOneMinsDuration < tripTwoMinsDuration;

    // prepare duration string
    const duration = minutesDuration.some((num) => num > 0)
        ? isFaster
            ? `${humanReadTimeString(minutesDuration)} ${translate('shorter')}`
            : `${humanReadTimeString(minutesDuration)} ${translate('longer')}`
        : '';

    return { duration, isFaster };
};

export function isSameDay(day1: string, day2: string): boolean {
    return dayjs(day1).isSame(day2, 'day');
}
