import {
    Autocomplete,
    AutocompleteInput,
    AutocompleteOptions,
    Button,
} from '@flixbus/honeycomb-react';

import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { AutocompleteStopsList } from '../types';

import './StopSelector.scss';
import { Icon, IconClose, IconSwitch } from '@flixbus/honeycomb-icons-react';
import { useTranslate } from '../../system/i18n/useTranslate';

enum TStop {
    FROM = 'FROM',
    TO = 'TO',
}

function accentedNormalize(input: string): string {
    return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
function regExpNormalize(input: string): string {
    return input.replace(/(\[|\]|\(|\)|\*|\.|\^|\{|\}|\+|\?|\\)/g, '\\$1');
}

function search(
    query: string,
    data: AutocompleteStopsList[]
): AutocompleteStopsList[] {
    if (query === '' || query.length < 2) {
        return [];
    }
    return data
        .filter((item) => {
            const regExpNormalized = regExpNormalize(query);
            const accentedNormalized = accentedNormalize(regExpNormalized);
            const regExp = new RegExp(`(?:\\b|\\s)${accentedNormalized}`, 'i');
            const codeMatch =
                accentedNormalize(item.code).search(regExp) !== -1;
            const nameMatch =
                accentedNormalize(item.name).search(regExp) !== -1;
            return codeMatch || nameMatch;
        })
        .slice(0, 10);
}

type StopsSelectorAutocompleteProps = {
    options?: AutocompleteStopsList[];
    onSelect?: ([from, to]: (
        | Partial<AutocompleteStopsList>
        | undefined
    )[]) => void;
    onFocus?: () => void;
    loading?: boolean;
};

export default function StopsSelectorAutocomplete({
    options,
    onSelect,
    onFocus = () => {},
    loading = false,
}: StopsSelectorAutocompleteProps) {
    const translate = useTranslate();
    const [queryFrom, setQueryFrom] = useState('');
    const [queryTo, setQueryTo] = useState('');

    const [stopFrom, setStopFrom] = useState<
        Partial<AutocompleteStopsList> | undefined
    >();
    const [stopTo, setStopTo] = useState<
        Partial<AutocompleteStopsList> | undefined
    >();
    const selectCache = useRef<any[]>([]);
    useEffect(() => {
        if (
            selectCache.current[0]?.id !== stopFrom?.id ||
            selectCache.current[1]?.id !== stopTo?.id
        ) {
            onSelect && onSelect([stopFrom, stopTo]);
            selectCache.current = [stopFrom, stopTo];
        }
    }, [stopFrom, stopTo, onSelect]);

    const onDebounceCallback = useCallback(
        function onDebounceCallback(type: TStop) {
            return function onDebounce(e: ChangeEvent<HTMLInputElement>) {
                switch (type) {
                    case TStop.FROM:
                        setQueryFrom(e.target.value);
                        break;
                    case TStop.TO:
                        setQueryTo(e.target.value);
                        break;
                    default:
                        break;
                }
            };
        },
        [setQueryFrom, setQueryTo]
    );

    const reset = useCallback(() => {
        if (stopFrom || stopTo) {
            setStopFrom(undefined);
            setStopTo(undefined);
        }
    }, [stopFrom, stopTo]);

    function onSelectCallback(type: TStop) {
        return function onSelect(item: Partial<AutocompleteStopsList>) {
            switch (type) {
                case TStop.FROM:
                    setQueryFrom('');
                    setStopFrom(item);
                    break;
                case TStop.TO:
                    setQueryTo('');
                    setStopTo(item);
                    break;
                default:
                    break;
            }
        };
    }

    const swapValues = useCallback(() => {
        setStopTo(stopFrom);
        setStopFrom(stopTo);
    }, [stopFrom, stopTo]);

    return (
        <div className="rs-combo-picker">
            <div className="rs-combo-picker__box">
                <div className="rs-combo-picker__left">
                    <Autocomplete
                        onDebounce={onDebounceCallback(TStop.FROM)}
                        onSelect={onSelectCallback(TStop.FROM)}
                        options={search(queryFrom, options || [])}
                        value={stopFrom?.title || ''}
                        onReset={() => {}}
                    >
                        <AutocompleteInput
                            label={translate('stops-picker.label.from')}
                            id="autocomplete-from"
                            onFocus={onFocus}
                            loading={loading}
                            autoComplete="off"
                        />
                        <AutocompleteOptions
                            label="Stops"
                            optionsToDisplay={5}
                        />
                    </Autocomplete>
                </div>
                <div className="rs-combo-picker__right">
                    <Autocomplete
                        onDebounce={onDebounceCallback(TStop.TO)}
                        onSelect={onSelectCallback(TStop.TO)}
                        options={search(queryTo, options || [])}
                        value={stopTo?.title || ''}
                    >
                        <AutocompleteInput
                            label={translate('stops-picker.label.to')}
                            id="autocomplete-to"
                            onFocus={onFocus}
                            loading={loading}
                            autoComplete="off"
                        />
                        <AutocompleteOptions
                            label="Stops"
                            optionsToDisplay={5}
                        />
                    </Autocomplete>
                </div>
                <button
                    className="rs-combo-picker__switch"
                    onClick={swapValues}
                >
                    <Icon appearance="primary" InlineIcon={IconSwitch} />
                </button>
            </div>
            <div className="rs-combo-picker__actions">
                <Button link onClick={reset}>
                    <Icon appearance="primary" InlineIcon={IconClose} />
                </Button>
            </div>
        </div>
    );
}
