import IcInfo, { IcRideAttr } from './IcInfo';
import { Capacity, BreakingPoint } from '../../components/types';

import RideBase, { AttrsBase } from './RideBase';
import Ride, { getRideUid } from './Ride';

export type AttrsBaseTrip = AttrsBase & {
    legacyUid?: string;
    icInfo?: IcRideAttr[];
    suggestions?: AttrSuggestion[];
    uid?: string;
    id: string;
    suggestionType: 'FULL' | 'PARTIAL';
    rideUuid: string;
    lineColor?: string;
    type?: 'DIRECT' | 'INTERCONNECTION';
    lineDetails?: { code: string; color: string };
};

export type AttrSuggestion = AttrsBase & {
    uid: string;
    legacyUid: string;
    capacity: Capacity;
    gettingStatus?: string;
    gettingMessage?: string | null;
    disabled?: boolean;
    suggestionTripType?: 'DIRECT' | 'INTERCONNECTION';
    rides?: (Omit<AttrsBase, 'destinationStationCode' | 'startStationCode'> & {
        rideUuid: string;
        lineColor: string;
        sortOrder?: number;
        lineNumber?: string;
        tripNumber?: number;
        isPassed?: boolean;
        destinationStationCode?: string;
        startStationCode?: string;
    })[];
};

export type AttrsBaseTripCustom = AttrSuggestion & {
    isCustom: boolean;
    initTripInfo?: {
        startStationCode: string;
        destinationStationCode: string;
        startStationName: string;
        destinationStationName: string;
        arrivalTime: string;
        departureTime: string;
    };
    breakingPoint?: BreakingPoint;
};

export default class Trip extends RideBase {
    rideUid?: string;
    legacyUid!: string;
    icInfo: IcInfo;
    suggestions!: SuggestionsCollection;
    suggestionTripType?: 'DIRECT' | 'INTERCONNECTION';
    status?: 'CANCELLED' | 'PAID';
    id!: string;
    uid!: string;
    rides!: Ride[];
    lineColor?: string;

    constructor(object: AttrsBaseTrip) {
        const icRides = object.icInfo;
        super(object);
        Object.assign(this, object);
        this.icInfo = new IcInfo(...(icRides || []));
        if (object.type === 'DIRECT') {
            this.rideUid = getRideUid(object);
            this.lineColor = object.lineDetails?.color || '';
        }

        if (Array.isArray(object.suggestions)) {
            this.suggestions = new SuggestionsCollection(object.suggestions);
        }
    }
}

export class Suggestion extends RideBase {
    uid!: string;
    legacyUid!: string;
    capacity!: Capacity;
    gettingStatus?: string;
    gettingMessage?: string | null;
    disabled?: boolean;
    suggestionTripType?: 'DIRECT' | 'INTERCONNECTION';
    icInfo: IcInfo;
    rideUid!: string;

    constructor(trip: AttrSuggestion) {
        super(trip);
        Object.assign(this, trip);
        const icRides = trip.rides;
        this.icInfo = new IcInfo(...(icRides || []));
    }
}

export class CustomSuggestion extends Suggestion {
    disabled?: boolean;
    isCustom?: boolean;
    initTripInfo?: {
        startStationCode: string;
        destinationStationCode: string;
        startStationName: string;
        destinationStationName: string;
        arrivalTime: string;
        departureTime: string;
    };
    breakingPoint?: BreakingPoint;
    constructor(object: AttrsBaseTripCustom) {
        super(object);
        Object.assign(this, object);
    }
}

export class SuggestionsCollection extends Array<Suggestion> {
    constructor(arr: AttrSuggestion[]) {
        let suggestions: any[] = [];
        // filters out suggestions with invalid data
        const validSuggestions = arr.filter((sg) => {
            const isInvalidRides = sg.rides?.some((ride) => {
                return ride.arrivalTime === null || ride.departureTime === null;
            });
            return (
                sg.arrivalTime !== null &&
                sg.departureTime !== null &&
                !isInvalidRides
            );
        });
        suggestions = validSuggestions.map((sg) => {
            return new Suggestion(sg);
        });

        super(...suggestions);
    }
    static get [Symbol.species]() {
        return Array;
    }
}
