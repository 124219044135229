import {
    Accordion,
    Box,
    Grid,
    GridCol,
    Heading,
    Text,
    Button,
} from '@flixbus/honeycomb-react';
import { Icon, IconArrowLeft } from '@flixbus/honeycomb-icons-react';

export default function ErrorUiComponent({ error, resetError = () => {} }) {
    return (
        <Grid>
            <GridCol size={2} push={1}>
                <Button link onClick={resetError}>
                    <Icon InlineIcon={IconArrowLeft} />
                    Back to App
                </Button>
            </GridCol>
            <GridCol size={6}>
                <Box>
                    <Heading size={1}>Sorry, error happens.</Heading>
                    <Text>
                        We already know about this issue. Don&#39;t worry.
                    </Text>
                    <Accordion title="Technical details">
                        <Text>{String(error)}</Text>
                    </Accordion>
                </Box>
            </GridCol>
        </Grid>
    );
}
