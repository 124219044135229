import { api } from '../api';
import {
    QueryFunctionContext,
    useQuery,
    QueryObserverOptions,
    UseQueryResult,
} from '@tanstack/react-query';

import { AxiosResponse } from 'axios';
import {
    AttrsBaseTrip,
    SuggestionsCollection,
    AttrSuggestion,
} from './classes/Trip';

interface RBody {
    body: { content: { rebookingOptions: AttrSuggestion[] }[] };
}

function fetch({
    queryKey,
}: QueryFunctionContext): Promise<SuggestionsCollection> {
    const [, rideId, orderIds, suggestionsTimestamp, from, to] = queryKey;
    const url: string =
        '/suggestions/ride/rebookingOptions/orderGroupSuggestions';
    return api
        .post<AttrsBaseTrip, AxiosResponse<RBody>>(url, {
            rideId,
            orderIds,
            suggestionsTimestamp,
            fromStationId: from,
            toStationId: to,
        })
        .then((response) => {
            const sMap = new Map<string, AttrSuggestion>();
            const data = response.data.body.content || response.data.body;
            data.forEach((item) => {
                item.rebookingOptions.forEach((s) => {
                    sMap.set(s.legacyUid as string, s);
                });
            });
            return new SuggestionsCollection(
                Array.from(sMap)
                    .map(([, value]) => value)
                    .sort((a, b) => {
                        const timeA = new Date(a.departureTime).getTime();
                        const timeB = new Date(b.departureTime).getTime();
                        return timeA - timeB;
                    })
            );
        })
        .catch((e) => {
            if (e.response?.data?.error && e.response?.data?.errorMsg) {
                throw new Error(
                    `${e.response.data.error}: ${e.response.data.errorMsg}`
                );
            }
            throw new Error(`${e.code}: ${e.message}`);
        });
}

export default function useGroupSuggestions(
    rideUuid: string,
    orders: (number | string)[],
    date: string | number,
    [from, to]: (string | undefined)[],
    options: QueryObserverOptions<SuggestionsCollection> = {}
): UseQueryResult<SuggestionsCollection> {
    return useQuery({
        queryKey: ['groupSuggestions', rideUuid, orders, date, from, to],
        queryFn: fetch,
        ...options,
    });
}
