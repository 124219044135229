import { datadogRum } from '@datadog/browser-rum';
import { getUser } from '../auth';

const userObject = getUser();
const isProd = process.env.NODE_ENV === 'production';
const clientToken = process.env.REACT_APP_DD_CLIENT_TOKEN || '';

datadogRum.init({
    applicationId: 'eab7cfc9-df3c-46db-be43-8bc698224e14',
    clientToken: clientToken,
    site: 'datadoghq.eu',
    service: 'fxt.ops.services.rebookr-fe',
    env: isProd ? 'prod' : 'stage',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '2.0.0',
    sessionSampleRate: isProd ? 100 : 0,
    sessionReplaySampleRate: isProd ? 20 : 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [
        /https:\/\/.*\.flixbus.com\/api\/.*/,
        /https:\/\/.*\.flix.tech\/api\/.*/,
    ],
});
if (userObject !== null) {
    const {
        // @ts-ignore
        profile: { name, roles },
        userName,
    } = userObject;
    datadogRum.setUser({ name, userName, roles });
    datadogRum.startSessionReplayRecording();
}

/**
 * Send custom actions
 * https://docs.datadoghq.com/real_user_monitoring/guide/send-rum-custom-actions/?tab=npm
 */
